import { isProduction } from './utils/env'

export const NEW_BOT_PERCENTAGE = 0.1

export const languages = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  PORTUGUESE: 'pt',
  DUTCH: 'nl',
  SPANISH: 'es',
}

export const countries = {
  GREAT_BRITAIN: 'GB',
  FRANCE: 'FR',
  ITALY: 'IT',
  GERMANY: 'DE',
  PORTUGAL: 'PT',
  NETHERLANDS: 'NL',
  SPAIN: 'ES',
}

export const countriesByLanguage = {
  [languages.ENGLISH]: countries.GREAT_BRITAIN,
  [languages.FRENCH]: countries.FRANCE,
  [languages.ITALIAN]: countries.ITALY,
  [languages.GERMAN]: countries.GERMANY,
  [languages.PORTUGUESE]: countries.PORTUGAL,
  [languages.DUTCH]: countries.NETHERLANDS,
  [languages.SPANISH]: countries.SPAIN,
}

export const DEFAULT_LANGUAGE_SLUG = 'en'
export const DEFAULT_LANGUAGE = languages.ENGLISH
export const LANGUAGE_SLUGS: Record<string, string> = {
  nl: languages.DUTCH,
  en: languages.ENGLISH,
  fre: languages.FRENCH,
  fr: languages.FRENCH,
  ger: languages.GERMAN,
  de: languages.GERMAN,
  ita: languages.ITALIAN,
  it: languages.ITALIAN,
  pt: languages.PORTUGUESE,
  es: languages.SPANISH,
}
export const languagesWithDirectHandoff = [languages.SPANISH, languages.PORTUGUESE, languages.DUTCH]
export const HANDOFF_PAYLOAD = 'handoff'
export const INITIAL_PAYLOAD = 'initial'

export const faqBotonicUrl = isProduction()
  ? 'https://webchat-sdk.hubtype.com/easyjet/faqs/webchat.botonic.js'
  : 'https://faqs-bot-staging-6176ceda-94b6-4555-ad48-eb7bafe87794.netlify.app/webchat.botonic.js'
export const faqBotonicAppId = isProduction()
  ? '03822d1f-0067-4171-ad06-feaafeea1450'
  : 'bc8e7d06-9f5e-4c64-a013-44d08f9f883b'
export const newFaqBotonicUrl = isProduction()
  ? 'https://webchat-sdk.hubtype.com/easyjet/faqs/v2/webchat.botonic.js'
  : 'https://ej-flow-builder-test-175974c2-d4f8-4770-86d0-d40ff6a57657.netlify.app/webchat.botonic.js'
export const newFaqBotonicAppId = isProduction()
  ? '3d83d294-03a9-4e84-90dd-83542ca1d273'
  : '92991318-0a40-420a-ab45-40261a2c30b2'
export const BOT_STORAGE_KEY = 'botonicLocalState'
export const BOTONIC_STORAGE_KEY = 'botonicState'

export const htmlHeadContents = {
  [languages.ENGLISH]: {
    title: 'Contact easyJet | Chat to easyJet for help managing your booking',
    description: "Contact easyJet today with our chat tool! We'll answer your queries and help manage your booking",
  },
  [languages.GERMAN]: {
    title: 'Kontaktieren Sie easyJet | Chatten Sie mit easyJet, um Hilfe bei der Verwaltung Ihrer Buchung zu erhalten',
    description: "Kontaktieren Sie easyJet noch heute mit unserem Chat-Tool! Wir beantworten Ihre Fragen und helfen Ihnen bei der Verwaltung Ihrer Buchung",
  },
  [languages.FRENCH]: {
    title: "Contactez easyJet | Discutez avec easyJet pour obtenir de l'aide dans la gestion de votre réservation",
    description: "Contactez easyJet dès aujourd'hui avec notre outil de chat! Nous répondrons à vos questions et vous aiderons à gérer votre réservation",
  },
  [languages.ITALIAN]: {
    title: 'Contatta easyJet | Chatta con easyJet per ricevere assistenza nella gestione della tua prenotazione',
    description: "Contatta easyJet oggi stesso con il nostro strumento di chat! Risponderemo alle tue domande e ti aiuteremo a gestire la tua prenotazione",
  },
  [languages.SPANISH]: {
    title: 'Contacta con easyJet | Chatea con easyJet para que te ayuden a gestionar tu reserva',
    description: 'Ponte en contacto con easyJet hoy mismo a través de nuestra herramienta de chat. Responderemos a tus preguntas y te ayudaremos a gestionar tu reserva.',
  },
  [languages.PORTUGUESE]: {
    title: 'Contactar easyJet | Envie-nos uma mensagem para poder gerir a sua reserva',
    description: 'Contacte-nos hoje através do nosso chat! Iremos responder-lhe as suas perguntas e ajudaremos a gerir a sua reserva',
  },
  [languages.DUTCH]: {
    title: 'Neem contact op met easyJet | Chat met easyJet voor hulp bij het beheren van uw boeking',
    description: 'Neem vandaag nog contact op met easyJet via onze chattool! Wij beantwoorden uw vragen en helpen u uw boeking te beheren.',
  },
}
